import React from "react";



import "../HeroSection/new-styles.scss";

const NewHighlightBar = () => {
  return (
    <div className="ks-live-benefits">
      <div className="benefits-header">Don't miss out, here's why :</div>
      <div className="benefits-images-container">
        <div className="benefits-image">
          <div>
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ysqr4p.png" loading="lazy" className="free-img" />
          </div>
          <h3 className="benefit-text">Free Session</h3>
        </div>
        <div className="benefits-image">
          <div style={{paddingTop: '1.4em'}}>
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yta6fp.png"  loading="lazy" style={{height: '92px'}} className="live-img" />
          </div>
          <h3 className="benefit-text" >Live Streaming</h3>
        </div>
        <div className="benefits-image" style={{marginTop: '1.5em'}}>
          <div>
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ysspfl.png" loading="lazy" className="expert-img" />
          </div>
          <h3>Insights from industry experts</h3>
        </div>
      </div>
      <div className="benefits-image-mobile">
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yrv5fm.png" loading="lazy" className="live-img" />
      </div>
    </div>
  );
};

export default NewHighlightBar;
